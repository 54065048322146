import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/nedix/projects/internal/nedix.io/git_modules/gatsby-theme-carbon/packages/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Troubleshooting`}</h2>
    <h3>{`Disable a problematic Docker Compose service`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-yaml",
        "metastring": "path=docker-compose.override.yml",
        "path": "docker-compose.override.yml"
      }}>{`version: "3.8"

services:
  bad-service:
    deploy:
      replicas: 0
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      